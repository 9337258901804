import { Language } from "../types/General";
import dayjs from "dayjs";

export const formatDate = (d: string, language: Language) => {
  let format = "D MMM YYYY";
  if (language === Language.enUS) format = "MMM D YYYY";

  return dayjs(d).format(format);
};

export const formatTime = (d: string) => {
  return dayjs(d).format("HH:mm");
};

export const formatDateTime = (d: string, language: Language) => {
  let format = "D MMM YYYY";
  if (language === Language.enUS) format = "MMM D YYYY";
  return dayjs(d).format(format + " HH:mm");
};

export const getAge = (d: string) => {
  return dayjs().diff(d, "year");
};

export const formatDobForForm = (d: string, language: Language) => {
  if (d) {
    let format = "DD-MM-YYYY";
    if (language === Language.enUS) format = "MM-DD-YYYY";

    return dayjs(d).format(format);
  }
};
