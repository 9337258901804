import { faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { get } from "../../api/Request";
import ConfirmButton from "../../components/helper/ConfirmButton";
import ValueLabel from "../../components/layout/ValueLabel";
import { useGlobalContext } from "../../context/GlobalContext";
import { formatDate, formatDobForForm, getAge } from "../../helpers/dateHelper";
import { Patient2 } from "../../types/Patient";
import UpsertPatientModal from "./UpsertPatientModal";
import PatientHistoryModal from "./PatientHistoryModal";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

interface Props {
  id: number;
}

const PatientInfo: React.FC<Props> = ({ id }) => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const { user } = useAuth();
  const { language } = useGlobalContext();
  const navigate = useNavigate();

  const [profile, setProfile] = useState<Patient2>();

  const [showPatientModal, setShowPatientModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [, setLoading] = useState(false);
  const [integrationLoading, setIntegrationLoading] = useState(false);

  const getPatient = () => {
    setLoading(true);
    get<Patient2>("patient/info/" + id)
      .then(r => {
        // console.log(r.data);
        setProfile(r.data);
      })
      .catch(_ => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getPatient();
  }, []);

  return (
    <div className="patient-info">
      {profile && (
        <>
          <div className="center">
            <FontAwesomeIcon
              style={{ color: "grey-6" }}
              icon={faUser}
              size="4x"
            />
            <Title level={4}>
              {profile.title} {profile.first_name} {profile.last_name}
            </Title>
          </div>
          <Button
            className="margin-bottom"
            type="default"
            block
            onClick={() => setShowHistoryModal(true)}
          >
            {t("patient.showHistory")}
          </Button>
          {user && user.clinic.integration_enabled === "cliniko" && (
            <div style={{ marginBottom: 16 }}>
              {profile.integration_id === "0" && (
                <Button
                  block
                  type="dashed"
                  loading={integrationLoading}
                  onClick={_ => {
                    setIntegrationLoading(true);
                    get("cliniko/addPatientToCliniko/" + profile.id)
                      .then(r => {
                        getPatient();
                      })
                      .catch(e => {})
                      .finally(() => setIntegrationLoading(false));
                  }}
                >
                  {t("integrations.addToIntegration", {
                    integration: "Cliniko"
                  })}
                </Button>
              )}
              {profile.integration_id !== "0" && (
                <>
                  <Button
                    block
                    type="primary"
                    className="margin-bottom"
                    onClick={_ => {
                      window.open(
                        "https://" +
                          user.clinic.integration_subdomain +
                          ".cliniko.com/patients/" +
                          profile.integration_id,
                        "_blank"
                      );
                    }}
                  >
                    {t("integrations.goToProfile", {
                      integration: "Cliniko"
                    })}
                  </Button>
                  <Button
                    block
                    type="primary"
                    onClick={_ => {
                      get(
                        "cliniko/fetchDataFromCliniko/" + profile.integration_id
                      )
                        .then(r => {
                          getPatient();
                        })
                        .catch(e => {});
                    }}
                  >
                    {t("cliniko.fetchDataFromCliniko")}
                  </Button>
                </>
              )}
            </div>
          )}
          {user && user.clinic.integration_enabled === "sif" && (
            <div style={{ marginBottom: 16 }}>
              {profile.integration_id === "0" && (
                <Alert message={t("integrations.notOnSif")} type="warning" />
              )}
              {profile.integration_id !== "0" && (
                <Button
                  block
                  type="primary"
                  onClick={_ => {
                    window.open(
                      "https://www.sif.health/my-business/patient/" +
                        profile.integration_id,
                      "_blank"
                    );
                  }}
                >
                  {t("integrations.goToProfile", {
                    integration: "SIF"
                  })}
                </Button>
              )}
            </div>
          )}
          {user && user.clinic.integration_enabled === "nookal" && (
            <div style={{ marginBottom: 16 }}>
              {profile.integration_id === "0" && (
                <Button
                  block
                  type="dashed"
                  loading={integrationLoading}
                  onClick={_ => {
                    setIntegrationLoading(true);
                    get("nookal/addPatientToNookal/" + profile.id)
                      .then(r => {
                        getPatient();
                      })
                      .catch(e => {})
                      .finally(() => setIntegrationLoading(false));
                  }}
                >
                  {t("integrations.addToIntegration", {
                    integration: "Nookal"
                  })}
                </Button>
              )}
              {profile.integration_id !== "0" && (
                <Button
                  block
                  type="primary"
                  onClick={_ => {
                    window.open(
                      "https://euzone1.nookal.com/v2.0/clients/profile/1/" +
                        profile.integration_id,
                      "_blank"
                    );
                  }}
                >
                  {t("integrations.goToProfile", {
                    integration: "Nookal"
                  })}
                </Button>
              )}
            </div>
          )}
          <ValueLabel label={t("email")}>{profile.email}</ValueLabel>
          {profile.dob && (
            <ValueLabel label={t("form.dob")}>
              {formatDate(profile.dob, language)} (age {getAge(profile.dob)})
            </ValueLabel>
          )}

          <ValueLabel label={t("form.emailCC")}>{profile.email_cc}</ValueLabel>
          <ValueLabel label={t("form.emailBCC")}>{profile.email_cc}</ValueLabel>
          <ValueLabel label={t("phone")}>
            {profile.dial_code && "+" + profile.dial_code}
            {profile.phone && profile.phone}
          </ValueLabel>
          <ValueLabel label={t("form.exerciseImageType")}>
            {profile.image_type ? t("lineart") : t("photo")}
          </ValueLabel>
          <ValueLabel label={t("patient.trAccessCodeLabel")}>
            <Paragraph
              copyable={{
                text: "https://trackrehab.com/c/" + profile.access_code,
                tooltips: [t("copy"), t("copied")]
              }}
            >
              {profile.access_code}
            </Paragraph>
          </ValueLabel>
          <Button
            className="margin-bottom"
            type="primary"
            block
            onClick={() => setShowPatientModal(true)}
          >
            {t("patient.editPatient")}
          </Button>

          <ConfirmButton
            btnText={t("delete")}
            message={t("confirmations.deletePatient")}
            btnProps={{ block: true, danger: true }}
            onConfirm={() => {
              get("patient/delete/" + id)
                .then(() => {
                  navigate("/patients");
                })
                .catch(_ => {});
            }}
          />
          <UpsertPatientModal
            initialData={{
              ...profile,
              patient_consent: true,
              dob: profile.dob && formatDobForForm(profile.dob, language)
            }}
            showModal={showPatientModal}
            onDone={() => {
              setShowPatientModal(false);
              getPatient();
            }}
            onCancel={() => {
              setShowPatientModal(false);
            }}
          />
          <PatientHistoryModal
            patientId={profile.id ?? 0}
            showModal={showHistoryModal}
            onCancel={() => setShowHistoryModal(false)}
          />
        </>
      )}
    </div>
  );
};

export default PatientInfo;
